<template>
  <div>
    <div class="review_trip" v-if="!reviewed">
      <img src="../assets/images/dwellys-logo.png" alt="logo" width="135px" class="dlogo" />
      <br />
      <h3>
        Help us understand your thoughts on your last viewing by giving us a review.
      </h3>
      <br />
      <label><b>Somthing to tell us about your last property view</b></label>
      <el-input class="mt-1" type="textarea" placeholder="" v-model="review.body" />
      <div class="mt-1">
        <label><b>Rate Collaborator</b></label>
        <div class="mt-1">
          <el-rate
            v-model="review.customer_service_rating"
            :texts="['oops', 'disappointed', 'normal', 'good', 'great']"
            show-text
          >
          </el-rate>
        </div>
        <br />
        <label><b>Rate Property</b></label>
        <div class="mt-1">
          <el-rate
            v-model="review.property_rating"
            :texts="['oops', 'disappointed', 'normal', 'good', 'great']"
            show-text
          >
          </el-rate>
        </div>

        <br />
        <span class="d-block"
          ><b>Will you recommend to a friend: </b>
          <el-tooltip effect="light" :content="review.recommend" placement="top">
            <el-switch
              v-model="review.recommend"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-value="Yes"
              inactive-value="No"
            >
            </el-switch>
          </el-tooltip>
        </span>
        <br />
        <label><b>Overall Rating</b></label>
        <div class="mt-1 rate_window">
          <el-rate v-model="review.over_all_rating"> </el-rate>
        </div>
      </div>
      <br />
      <el-button
        class="full-width"
        type="primary"
        icon="el-icon-mouse"
        size="small"
        :loading="btnLoading"
        @click="sendReview"
        >Send Review</el-button
      >
    </div>

    <div class="review_trip" v-else>
      <img src="../assets/images/dwellys-logo.png" alt="logo" width="135px" class="dlogo" />
      <br />
      <h2>THANK YOU FOR YOUR REVIEW</h2>
      <img src="../assets/images/thankyou_roop.gif" alt="thank you" width="100%" />
    </div>
  </div>
</template>

<script>
import reviewsApi from "@/api/reviews";

export default {
  name: "Map",
  data() {
    return {
      reviewed: false,
      btnLoading: false,
      review: {
        trip_uuid: "",
        body: "",
        customer_service_rating: 1,
        quality_rating: 0,
        friendly_rating: 0,
        property_rating: 0,
        recommend: "Yes",
        over_all_rating: 4,
        approved: true
      }
    };
  },
  mounted() {
    this.review.trip_uuid = this.$route.query.trip_uuid;
  },
  methods: {
    sendReview() {
      this.btnLoading = true;
      reviewsApi
        .addReview(this.review)
        .then(response => {
          console.log(response);
          this.successNotification("Thank You", "Your review has been received");
          this.reviewed = true;
          this.btnLoading = false;
        })
        .catch(error => {
          this.btnLoading = false;
          this.errorMessage(error.message);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.review_trip {
  position: relative;
  border: 1px solid rgb(161, 160, 160);
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  margin: 20px auto;

  .dlogo {
    position: absolute;
    top: 0;
    font-size: 18px;
    line-height: 1;
    margin: -9px 0 0; /* half of font-size */
    background: #fff;
    padding: 0 10px;
  }
}
.rate_window {
  border: 1px solid rgb(226, 225, 225);
  border-radius: 3px;
  padding: 8px;
  text-align: center;
}
</style>
